import React, { useRef } from 'react'
import CustomButton from '../CustomButton'
import logoBiButton from '../../assets/img/logoBiBlanco.png'

const PaymentButton = ({
  selectAccountBi,
  iIsVisibleAddNewAccountBi,
  indicator,
  countryCode,
  textBtn,
  handlePaymentNewAccountBi,
  handlePaymentAccountBi,
  canClickButton,
  handlePayment,
  isVisibleSectionAddNewCard,
  isEnabledPayWithNewCard,
  isEnabledPayWithSavedCard,
}) => {
  const isProcessingPayment = useRef(false)

  const handleClick = value => {
    if (value) {
      isProcessingPayment.current = true
      iIsVisibleAddNewAccountBi
        ? handlePaymentNewAccountBi()
        : handlePaymentAccountBi()
    } else if (handlePayment) {
      isProcessingPayment.current = true
      handlePayment()
    }
  }

  return (
    <div className="claro__container__button">
      {!isProcessingPayment.current && (
        <>
          {(selectAccountBi !== null || iIsVisibleAddNewAccountBi) &&
          indicator &&
          countryCode === '507' ? (
            <CustomButton
              text={textBtn}
              onClick={() => handleClick(true)}
              customClass="claro__container__button__paymentBi"
              enabled={canClickButton}
              img={logoBiButton}
            />
          ) : (
            <CustomButton
              text={textBtn}
              onClick={() => handleClick(false)}
              customClass="claro__container__button__payment"
              enabled={
                (isVisibleSectionAddNewCard
                  ? isEnabledPayWithNewCard
                  : isEnabledPayWithSavedCard) && canClickButton
              }
            />
          )}
        </>
      )}
    </div>
  )
}

export default PaymentButton
