import React, { useEffect, useState, useMemo } from 'react'

import { getAxiosService } from '../../services/getAxiosService'
import { postAxiosService } from '../../services/postAxiosService'
import {
  getLocalStorage,
  clearLocalStorage,
  setLocalStorage,
} from '../../services/storage'

import ClaroPayContext from '../../context/ClaroPayContext'

import BACK_END_ROUTES from '../../constants/api_backend_url_supplier'
import localRoutes from '../../constants/routes'
import elementNameCodeMap from '../../constants/Metrics'
import { RESPONSE_CODE } from '../../constants/responseCode'
import {
  MSISDN_ADM,
  AUTH_TOKENS_ADM,
  PRODUCT_AUTH_ADM,
  UUID_PROCESS_ADM,
  CODE_RECURRENCE,
  CODE_CALLED_ME,
  CODE_BNPL,
  CODE_PAGA_TU_PRESTAMO,
  CODE_CBK_USSD,
  CODE_BILLING,
  CODE_ADM,
  CODE_SESSIONBI,
  CODE_DELETEACCOUNTBI,
  CODE_SEND_A_RECHARGE,
  CODE_DEMOBI,
  URL_KEY_TO_LOCALSTORAGE_CALLED_ME,
  URL_KEY_TO_LOCALSTORAGE_BNPL,
  URL_KEY_TO_LOCALSTORAGE_PAGA_TU_PRESTAMO,
  URL_KEY_TO_LOCALSTORAGE_CBK_USSD,
  URL_KEY_TO_LOCALSTORAGE_BILLING,
  URL_KEY_TO_LOCALSTORAGE_ADM,
  URL_KEY_TO_LOCALSTORAGE_SESSIONBI,
  URL_KEY_TO_LOCALSTORAGE_DELETEACCOUNTBI,
  URL_KEY_TO_LOCALSTORAGE_RECURRENCE,
  URL_KEY_TO_LOCALSTORAGE_SEND_A_RECHARGE,
  URL_KEY_TO_LOCALSTORAGE_DEMOBI,
  PRODUCT_SELECTED_HOME,
  MENU_REDIRECT,
  MENU_CATEGORY,
  PROCESO_PAGO,
  METRICS_SESSION,
  PAYMENT_PROCESS,
  ADM_PROCESS,
  MENU_OPTION_SUPERPACKS,
  MENU_OPTION_RECARGAS,
  MENU_OPTION_ADM,
  MENU_OPTION_PAGARDEUDA,
  MENU_OPTION_ENVIARECARGA,
  MENU_TYPE_VERTICAL,
  MENU_OPTION_CERRARSESION,
} from '../../constants/ClaroPayConstants'

import { encryptData } from '../../components/EncryptData'
import { getImage } from '../../components/GetImage'
import { DeepLinkBi } from '../../components/DeepLinkBi'
import handleRedirectHome from '../../components/HandleRedirectHome'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import NextButton from '../../components/NextButton'
import LoadingScreen from '../../components/LoadingScreen'
import Menu from '../../components/Menu'
import { clearStorageLandings } from '../../components/ClearStorageLandings'
import ProcessPaymentResponse from '../../components/ProcessPaymentResponse'

import notPagaTuPrestamoImage from '../../assets/img/notPagaTuPrestamo.svg'
import notAdmImage from '../../assets/img/notAdm.svg'
import sad from '../../assets/img/sad.svg'

const ClaroPayLayout = ({ children }) => {
  const { processPaymentResponse } = ProcessPaymentResponse()

  const {
    routesClaroPay: {
      claroPagaTuPrestamo,
      claroBuyNowPayLater,
      claroWhoCalledMe,
      claroCallBackUssd,
      claroRecurrence,
      claroSendARecharge,
      claroDemoBi,
      claroAdm,
      claroHomeAuth,
    },
  } = localRoutes

  const {
    SESSION_CODE_EXPIRED,
    VALIDATION_CODE_EXPIRED,
    FAIL_SERVER_CLARO_PAY,
    OK_ADM,
    UNKNOWN_ADM,
    PAGA_TU_PRESTAMO_ADM,
    CON_SALDO_ADM,
    NOT_LOCATED_ANSWER_ADM,
    SESSION_UNDEFINED_ADM,
    NOT_PERMISSIONS_ADM,
    DOES_NOT_APPLY,
    OK,
    FAIL_SERVER_CLARO_BILLING,
    SESSION_CODE_EXPIRED_BILLING,
    BILLING_HAS_ALREADY_BEEN_GENERATED,
  } = RESPONSE_CODE

  const [recurrenceData, setRecurrenceData] = useState(null)
  const [code, setCode] = useState('')
  const [selectedSavedCard, setSelectedSavedCard] = useState({})
  const [
    isAcceptedTermsAndConditions,
    setIsAcceptedTermsAndConditions,
  ] = useState(true)
  const [isVisibleSectionAddNewCard, setIsVisibleSectionAddNewCard] = useState(
    false,
  )
  const [
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
  ] = useState(false)
  const [iIsVisibleAddNewAccountBi, setIsVisibleAddNewAccountBi] = useState(
    false,
  )
  const [selectAccountBi, setSelectAccountBi] = useState(null)
  const [requestId, setRequestId] = useState(null)
  const [urlAccountBi, setUrlAccountBi] = useState('')
  const [urltiendaApps, setUrltiendaApps] = useState('')
  const [saveCard, setSaveCard] = useState(false)
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [saveNewCard, setSaveNewCard] = useState(false)
  const [yearNewCard, setYearNewCard] = useState('')
  const [monthNewCard, setMonthNewCard] = useState('')
  const [cvv, setCvv] = useState('')
  const [numberCard, setNumberCard] = useState('')
  const [maxLengthCvv, setMaxLengthCvv] = useState(3)
  const [maxLengthNumberCard, setMaxLengthNumberCard] = useState(16)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [responseService, setResponseService] = useState(null)
  const [responseServiceBilling, setResponseServiceBilling] = useState(null)
  const [processingBilling, setProcessingBilling] = useState(false)
  const [isEnabledPayWithSavedCard, setIsEnabledPayWithSavedCard] = useState(
    false,
  )
  const [isEnabledPayWithNewCard, setIsEnabledPayWithNewCard] = useState(false)
  const [callMeData, setCallMeData] = useState(null)
  const [callMeProduct, setCallMeProduct] = useState(null)
  const [bnplData, setBnplData] = useState(null)
  const [pagaTuPrestamoData, setPagaTuPrestamoData] = useState(null)
  const [pagaTuPrestamoProduct, setPagaTuPrestamoProduct] = useState(null)
  const [cbkUssdData, setCbkUssdData] = useState(null)
  const [cbkUssdProduct, setCbkUssdProduct] = useState(null)
  const [billingData, setBillingData] = useState(null)
  const [admData, setAdmData] = useState(null)
  const [productsAdm, setProductsAdm] = useState(null)
  const [selectedPackAdm, setSelectedPackAdm] = useState(null)
  const [loadingAdm, setLoadingAdm] = useState(false)
  const [sessionBiData, setSessionBiData] = useState(null)
  const [deleteAccountBiData, setDeleteAccountBiData] = useState(null)
  const [failureBiData, setFailureBiData] = useState(null)
  const [sendARechargeData, setSendARechargeData] = useState(null)
  const [sendARechargeProduct, setSendARechargeProduct] = useState(null)
  const [demoBiData, setDemoBiData] = useState(null)
  const [pruebasData, setPruebasData] = useState(null)
  const [tokenAdm, setTokenAdm] = useState({
    valid: false,
    token: '',
  })
  const [currentCategoryMetrics, setCurrentCategoryMetrics] = useState(
    'Recarga-',
  )
  const [msisdnRechargeMetrics, setMsisdnRechargeMetrics] = useState('_B')
  const [canClickButton, setCanClickButton] = useState(true)
  const [menuVisible, setMenuVisible] = useState(false)
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null)
  const [exitFunctionAdm, setExitFunctionAdm] = useState(null)
  const [notPagaTuPrestamo, setNotPagaTuPrestamo] = useState(false)
  const [notAdm, setNotAdm] = useState(false)
  const [codeBilling, setCodeBilling] = useState(null)

  const menuCategory = getLocalStorage(MENU_CATEGORY)

  useEffect(() => {
    menuCategory && clearLocalStorage(MENU_CATEGORY)
    if (admData) {
      setOpcionSeleccionada(MENU_OPTION_ADM)
    } else if (pagaTuPrestamoData) {
      setOpcionSeleccionada(MENU_OPTION_PAGARDEUDA)
    } else if (sendARechargeData) {
      setOpcionSeleccionada(MENU_OPTION_ENVIARECARGA)
    } else {
      setOpcionSeleccionada(null)
    }
  }, [admData, pagaTuPrestamoData, sendARechargeData])

  const isValidatedFieldsToPayWithSavedCard = () =>
    year !== '' &&
    Number(year) > 0 &&
    year.length === 2 &&
    month !== '' &&
    Number(month) > 0 &&
    isAcceptedTermsAndConditions === true

  const isValidatedFieldsToPayWithNewCard = () =>
    yearNewCard !== '' &&
    Number(yearNewCard) > 0 &&
    yearNewCard.length === 2 &&
    monthNewCard !== '' &&
    Number(monthNewCard) > 0 &&
    cvv !== '' &&
    Number(cvv) > 0 &&
    cvv.length === maxLengthCvv &&
    numberCard !== '' &&
    Number(numberCard) > 0 &&
    numberCard.length === maxLengthNumberCard &&
    isAcceptedTermsAndConditions === true

  useEffect(() => {
    setIsEnabledPayWithSavedCard(isValidatedFieldsToPayWithSavedCard())
  }, [year, month, isAcceptedTermsAndConditions])

  useEffect(() => {
    setIsEnabledPayWithNewCard(isValidatedFieldsToPayWithNewCard())
  }, [yearNewCard, monthNewCard, cvv, numberCard, isAcceptedTermsAndConditions])

  const clearData = () => {
    setSaveCard(false)
    setYear('')
    setMonth('')
    setSaveNewCard(false)
    setYearNewCard('')
    setMonthNewCard('')
    setCvv('')
    setNumberCard('')
    setSelectedSavedCard({})
    setIsVisibleSectionAddNewCard(false)
    setIsVisibleAddNewPaymentMethod(false)
    setIsVisibleAddNewAccountBi(false)
    setSelectAccountBi(null)
  }

  const handleRedirect = () => {
    let url = ''

    if (pagaTuPrestamoData) {
      url = `${claroPagaTuPrestamo}?code=${code}`
    }

    if (bnplData) {
      url = `${claroBuyNowPayLater}?code=${code}`
    }

    if (callMeData) {
      url = `${claroWhoCalledMe}?code=${code}`
    }

    if (cbkUssdData) {
      url = `${claroCallBackUssd}?code=${code}`
    }

    if (recurrenceData) {
      url = `${claroRecurrence}?code=${code}`
    }

    if (sendARechargeData) {
      url = `${claroSendARecharge}?code=${code}`
    }

    if (demoBiData) {
      url = `${claroDemoBi}?code=${code}`
    }

    return url
  }

  const processAdmResponse = async response => {
    switch (response.responseCode) {
      case OK_ADM.code:
        return OK_ADM

      case UNKNOWN_ADM.code:
        return UNKNOWN_ADM

      case PAGA_TU_PRESTAMO_ADM.code:
        return PAGA_TU_PRESTAMO_ADM

      case CON_SALDO_ADM.code:
        return CON_SALDO_ADM

      case NOT_LOCATED_ANSWER_ADM.code:
        return NOT_LOCATED_ANSWER_ADM

      case SESSION_UNDEFINED_ADM.code:
        return SESSION_UNDEFINED_ADM

      case NOT_PERMISSIONS_ADM.code:
        return NOT_PERMISSIONS_ADM

      case FAIL_SERVER_CLARO_PAY.code:
        return FAIL_SERVER_CLARO_PAY

      default:
        return response
    }
  }

  const resetStateAndClearStorage = () => {
    setProductsAdm([])
    setAdmData([])
    clearLocalStorage(URL_KEY_TO_LOCALSTORAGE_ADM)
    clearLocalStorage(CODE_ADM)
    clearLocalStorage(MSISDN_ADM)
    clearLocalStorage(AUTH_TOKENS_ADM)
    clearLocalStorage(PRODUCT_AUTH_ADM)
  }

  const checkProcessAdmResponse = async response => {
    switch (response.responseCode) {
      case OK_ADM.code:
        resetStateAndClearStorage()
        return OK_ADM

      case UNKNOWN_ADM.code:
        resetStateAndClearStorage()
        return UNKNOWN_ADM

      case PAGA_TU_PRESTAMO_ADM.code:
        setProductsAdm([])
        setAdmData([])
        return PAGA_TU_PRESTAMO_ADM

      case CON_SALDO_ADM.code:
        resetStateAndClearStorage()
        return CON_SALDO_ADM

      case NOT_LOCATED_ANSWER_ADM.code:
        resetStateAndClearStorage()
        return NOT_LOCATED_ANSWER_ADM

      case SESSION_UNDEFINED_ADM.code:
        resetStateAndClearStorage()
        return SESSION_UNDEFINED_ADM

      case NOT_PERMISSIONS_ADM.code:
        resetStateAndClearStorage()
        return NOT_PERMISSIONS_ADM

      case FAIL_SERVER_CLARO_PAY.code:
        resetStateAndClearStorage()
        return FAIL_SERVER_CLARO_PAY

      default:
        resetStateAndClearStorage()
        return response
    }
  }

  const getAndSetCode = codeConstant => {
    clearStorageLandings()
    let localCode = getLocalStorage(codeConstant)
    const { search } = window.location

    if (search !== '') {
      const code = search.split('=')[1]

      if (localCode === null || code !== localCode) {
        setLocalStorage(codeConstant, code)
        localCode = code
      }
    }

    return localCode
  }

  const getUrlTo = (urlKey, codeKey, backendRoute) => {
    let url = getLocalStorage(urlKey)
    let localCode = getLocalStorage(codeKey)
    const { search } = window.location

    if (search !== '') {
      if (url === null || localCode !== search) {
        url = `${backendRoute}${search}`
      } else {
        url = `${backendRoute}?code=${localCode}`
      }
      setLocalStorage(urlKey, url)
    }

    return url
  }

  const setDefaultValues = (obj, key, defaultValue) => {
    if (
      typeof obj[key] === 'undefined' ||
      (typeof obj[key] !== 'object' && !obj[key])
    ) {
      obj[key] = defaultValue
    }
  }

  const getRecurrenceData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_RECURRENCE)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_RECURRENCE,
        CODE_RECURRENCE,
        BACK_END_ROUTES.recurrencePayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        setRecurrenceData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getCalledMeData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_CALLED_ME)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_CALLED_ME,
        CODE_CALLED_ME,
        BACK_END_ROUTES.callMePayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        setCallMeData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getBnplData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_BNPL)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_BNPL,
        CODE_BNPL,
        BACK_END_ROUTES.bnplPayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        setBnplData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getPagaTuPrestamoData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_PAGA_TU_PRESTAMO)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_PAGA_TU_PRESTAMO,
        CODE_PAGA_TU_PRESTAMO,
        BACK_END_ROUTES.pagaTuPrestamoPayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        if (responseCode === OK.code) {
          setDefaultValues(responseContent, 'creditCardList', [])
          setDefaultValues(responseContent, 'alias', [])
          setDefaultValues(responseContent, 'indicator', false)

          responseContent.combo = true

          setPagaTuPrestamoData(responseContent)
        }

        if (responseCode === DOES_NOT_APPLY.code) {
          setNotPagaTuPrestamo(true)
          setTimeout(() => {
            handleRedirectHome()
          }, 5000)
        }

        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getCbkUssdData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_CBK_USSD)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_CBK_USSD,
        CODE_CBK_USSD,
        BACK_END_ROUTES.callBackUssdPayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        setCbkUssdData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const processBillingResponse = async response => {
    switch (response) {
      case OK.code:
        return OK

      case FAIL_SERVER_CLARO_BILLING.code:
        return FAIL_SERVER_CLARO_BILLING

      case SESSION_CODE_EXPIRED_BILLING.code:
        return SESSION_CODE_EXPIRED_BILLING

      case BILLING_HAS_ALREADY_BEEN_GENERATED.code:
        return BILLING_HAS_ALREADY_BEEN_GENERATED

      default:
        return response
    }
  }

  const getBillingData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_BILLING)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_BILLING,
        CODE_BILLING,
        BACK_END_ROUTES.billingPayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseCode } = response
        const responseBilling = await processBillingResponse(responseCode)
        setBillingData(responseBilling)
        setCode(currentCode)
        setCodeBilling(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getAdmData = async () => {
    try {
      setLoadingAdm(true)
      const currentCode = getAndSetCode(CODE_ADM)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_ADM,
        CODE_ADM,
        BACK_END_ROUTES.admPayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        if (responseCode === OK.code) {
          setAdmData(response)
        }

        if (responseCode === DOES_NOT_APPLY.code) {
          setNotAdm(true)
          setTimeout(() => {
            handleRedirectHome()
          }, 5000)
        }

        setCode(currentCode)
        setLoadingAdm(false)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getSessionBiData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_SESSIONBI)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_SESSIONBI,
        CODE_SESSIONBI,
        BACK_END_ROUTES.sessionAccountBi,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        setSessionBiData(response)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getDeleteAccountBiData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_DELETEACCOUNTBI)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_DELETEACCOUNTBI,
        CODE_DELETEACCOUNTBI,
        BACK_END_ROUTES.deleteAccountBi,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (
          responseCode === SESSION_CODE_EXPIRED.code ||
          responseCode === VALIDATION_CODE_EXPIRED.code
        ) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'alias', [])

        setDeleteAccountBiData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getSendARechargeData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_SEND_A_RECHARGE)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_SEND_A_RECHARGE,
        CODE_SEND_A_RECHARGE,
        BACK_END_ROUTES.sendARechargePayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        setSendARechargeData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getDemoBiData = async () => {
    try {
      const currentCode = getAndSetCode(CODE_DEMOBI)
      const url = getUrlTo(
        URL_KEY_TO_LOCALSTORAGE_DEMOBI,
        CODE_DEMOBI,
        BACK_END_ROUTES.recurrencemockPayment,
      )
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response
        const { responseCode } = response
        if (responseCode === SESSION_CODE_EXPIRED.code) {
          handleRedirectHome()
        }

        setDefaultValues(responseContent, 'creditCardList', [])
        setDefaultValues(responseContent, 'alias', [])
        setDefaultValues(responseContent, 'indicator', false)

        setDemoBiData(responseContent)
        setCode(currentCode)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const getPruebasData = async () => {
    try {
      const url = BACK_END_ROUTES.homeProduct
      if (url !== null) {
        const response = await getAxiosService(url)
        const { responseContent } = response

        setPruebasData(responseContent)
      }
    } catch (error) {
      handleRedirectHome()
    }
  }

  const responseErrorDefault = async () => {
    const response = { responseCode: FAIL_SERVER_CLARO_PAY.code }
    return await processPaymentResponse(
      response,
      handleRedirect,
      clearData,
      setCodeBilling,
    )
  }

  const payWithSavedCardRecurrence = async uuid => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.recurrencePaymentWithSavedCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardRecurrence = async uuid => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.recurrencePaymentWithNewCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithSavedCardCallMe = async (pack, msisdn, uuid) => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        msisdn: msisdn,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        productId: pack.productId,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      setCallMeProduct(pack)
      const url = BACK_END_ROUTES.callMePaymentWithSavedCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardCallMe = async (pack, msisdn, uuid) => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        msisdn: msisdn,
        productId: pack.productId,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      setCallMeProduct(pack)
      const url = BACK_END_ROUTES.callMePaymentWithNewCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithSavedCardBnpl = async uuid => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.bnplPaymentWithSavedCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardBnpl = async uuid => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.bnplPaymentWithNewCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithSavedCardPagaTuPrestamo = async (combo, uuid) => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        combo: combo,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      setPagaTuPrestamoProduct(combo)
      const url = BACK_END_ROUTES.pagaTuPrestamoPaymentWithSavedCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardPagaTuPrestamo = async (combo, uuid) => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        combo: combo,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      setPagaTuPrestamoProduct(combo)
      const url = BACK_END_ROUTES.pagaTuPrestamoPaymentWithNewCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithSavedCardCallBackUssd = async (pack, msisdn, uuid) => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        msisdn: msisdn,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        productId: pack.productId,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      setCbkUssdProduct(pack)
      const url = BACK_END_ROUTES.callBackUssdPaymentWithSavedCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardCallBackUssd = async (pack, msisdn, uuid) => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        msisdn: msisdn,
        productId: pack.productId,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      setCbkUssdProduct(pack)
      const url = BACK_END_ROUTES.callBackUssdPaymentWithNewCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithSavedCardSendARecharge = async (pack, msisdn, uuid) => {
    const expirationDateSavedEncrypted = await encryptData(
      `20${year}-${month.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        msisdn: msisdn,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveCard,
        productId: pack.productId,
        creditCard: {
          token: selectedSavedCard.tokenId,
          expirationDate: expirationDateSavedEncrypted,
        },
        transactionId: uuid,
      }
      setSendARechargeProduct(pack)
      const url = BACK_END_ROUTES.sendARechargePaymentWithSavedCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewCardSendARecharge = async (pack, msisdn, uuid) => {
    const numberCardEncrypted = await encryptData(numberCard)
    const cvvEncrypted = await encryptData(cvv)
    const expirationDateEncrypted = await encryptData(
      `20${yearNewCard}-${monthNewCard.padStart(2, '0')}`,
    )
    try {
      const data = {
        code: code,
        msisdn: msisdn,
        productId: pack.productId,
        conditionsAccepted: isAcceptedTermsAndConditions,
        save: saveNewCard,
        creditCard: {
          number: numberCardEncrypted,
          cvv: cvvEncrypted,
          expirationDate: expirationDateEncrypted,
        },
        transactionId: uuid,
      }
      setSendARechargeProduct(pack)
      const url = BACK_END_ROUTES.sendARechargePaymentWithNewCard
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiRecurrence = async uuid => {
    try {
      const data = {
        code: code,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.recurrencePaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiWhoCalledMe = async (pack, msisdn, uuid) => {
    try {
      setCallMeProduct(pack)
      const data = {
        code: code,
        productId: pack.productId,
        msisdn: msisdn,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.callMePaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiPagaTuPrestamo = async (combo, uuid) => {
    try {
      setPagaTuPrestamoProduct(combo)
      const data = {
        code: code,
        combo: combo,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.pagaTuPrestamoPaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiCallBackUssd = async (pack, msisdn, uuid) => {
    try {
      setCbkUssdProduct(pack)
      const data = {
        code: code,
        msisdn: msisdn,
        productId: pack.productId,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.callBackUssdPaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiBnpl = async uuid => {
    try {
      const data = {
        code: code,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.bnplPaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiSendARecharge = async (pack, msisdn, uuid) => {
    try {
      setSendARechargeProduct(pack)
      const data = {
        code: code,
        productId: pack.productId,
        msisdn: msisdn,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.sendARechargePaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithAccountBiDemoBi = async uuid => {
    try {
      const data = {
        callerUuid: 'testInProUUid',
        code: code,
        account: selectAccountBi,
        conditionsAccepted: isAcceptedTermsAndConditions,
        transactionId: uuid,
      }
      const url = BACK_END_ROUTES.recurrencemockPaymentWithAccountBi
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const payWithNewAccountBi = async (value, msisdn) => {
    setLocalStorage(PAYMENT_PROCESS, handleRedirect())
    try {
      let urlBackend = ''
      let urlBackendCompleta = ''

      if (pagaTuPrestamoData) {
        urlBackend = BACK_END_ROUTES.pagaTuPrestamoUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}&combo=${value}`
      } else if (cbkUssdData) {
        urlBackend = BACK_END_ROUTES.callBackUssdUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}&productId=${value}&msisdn=${msisdn}`
      } else if (callMeData) {
        urlBackend = BACK_END_ROUTES.callMeUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}&productId=${value}&msisdn=${msisdn}`
      } else if (recurrenceData) {
        urlBackend = BACK_END_ROUTES.recurrenceUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}`
      } else if (bnplData) {
        urlBackend = BACK_END_ROUTES.bnplUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}`
      } else if (sendARechargeData) {
        urlBackend = BACK_END_ROUTES.sendARechargeUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}&productId=${value}&msisdn=${msisdn}`
      } else if (demoBiData) {
        urlBackend = BACK_END_ROUTES.recurrencemockUuidAccountBi
        urlBackendCompleta = `${urlBackend}?code=${code}`
      }

      const response = await getAxiosService(urlBackendCompleta)
      const { responseContent } = response
      const responseRequestId = `requestId=${responseContent}`
      setRequestId(responseRequestId)
      const { deeplink, appStoreUrl } = DeepLinkBi(responseRequestId)
      setUrlAccountBi(deeplink)
      setUrltiendaApps(appStoreUrl)
    } catch (error) {
      handleRedirectHome()
      clearLocalStorage(METRICS_SESSION)
    }
  }

  const activeAdm = async productId => {
    const Authorization =
      getLocalStorage(AUTH_TOKENS_ADM) !== undefined &&
      getLocalStorage(AUTH_TOKENS_ADM) !== null
        ? { Authorization: `Bearer ${getLocalStorage(AUTH_TOKENS_ADM)}` }
        : {}

    const productAuthAdm = getLocalStorage(PRODUCT_AUTH_ADM)
    const uuidAdm = getLocalStorage(UUID_PROCESS_ADM)
    let localCode = getLocalStorage(CODE_ADM)

    try {
      let response
      if (productAuthAdm) {
        const url = BACK_END_ROUTES.admActiveLogin
        const data = { productId: productId, transactionId: uuidAdm }
        const headers = { ...Authorization }
        response = await postAxiosService(url, data, { headers })
      } else {
        const url = BACK_END_ROUTES.admActiveLink
        const data = {
          code: localCode,
          productId: productId,
          transactionId: uuidAdm,
        }
        response = await postAxiosService(url, data)
      }
      return await processAdmResponse(response)
    } catch (error) {
      return await processAdmResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    }
  }

  const checkProcessAdm = async uuidAdm => {
    setLoadingAdm(true)
    const session = { session: uuidAdm }
    try {
      const url = BACK_END_ROUTES.admCheckProcess
      const data = {}
      const headers = { ...session }
      const response = await postAxiosService(url, data, { headers })
      return await checkProcessAdmResponse(response)
    } catch (error) {
      return await checkProcessAdmResponse({
        responseCode: FAIL_SERVER_CLARO_PAY.code,
      })
    } finally {
      setLoadingAdm(false)
    }
  }

  const deleteAccountBi = async () => {
    try {
      const data = {
        code: code,
        account: selectAccountBi,
      }
      const url = BACK_END_ROUTES.deleteAccountBiDelete
      const response = await postAxiosService(url, data)
      return await processPaymentResponse(
        response,
        handleRedirect,
        clearData,
        setCodeBilling,
      )
    } catch (error) {
      return await responseErrorDefault()
    }
  }

  const extractSiteFromPath = () => {
    const pathname = window.location.pathname
    if (pathname.startsWith('/landing/')) {
      return pathname.slice('/landing/'.length)
    } else if (pathname.startsWith(claroDemoBi)) {
      return 'bi'
    }
    return ''
  }

  const effectMapping = [
    { path: 'recurrence', data: recurrenceData, fetchData: getRecurrenceData },
    { path: 'who-called-me', data: callMeData, fetchData: getCalledMeData },
    { path: 'buy-now-pay-later', data: bnplData, fetchData: getBnplData },
    {
      path: 'wallet-recovery',
      data: pagaTuPrestamoData,
      fetchData: getPagaTuPrestamoData,
    },
    { path: 'ussd', data: cbkUssdData, fetchData: getCbkUssdData },
    { path: 'billing', data: billingData, fetchData: getBillingData },
    { path: 'adm', data: admData, fetchData: getAdmData },
    { path: 'sesion', data: sessionBiData, fetchData: getSessionBiData },
    {
      path: 'delete-account-bi',
      data: deleteAccountBiData,
      fetchData: getDeleteAccountBiData,
    },
    {
      path: 'send-a-recharge',
      data: sendARechargeData,
      fetchData: getSendARechargeData,
    },
    { path: 'bi', data: demoBiData, fetchData: getDemoBiData },
    {
      path: 'failure',
      data: failureBiData,
      fetchData: () => setFailureBiData(true),
    },
    { path: 'billing-process', data: null, fetchData: handleRedirectHome },
    { path: 'pruebas', data: pruebasData, fetchData: getPruebasData },
  ]

  useEffect(() => {
    const site = extractSiteFromPath()
    effectMapping.forEach(({ path, data, fetchData }) => {
      if ((data === null || data === undefined) && site === path) {
        fetchData()
      }
    })

    if (!effectMapping.some(({ path }) => path === site)) {
      if (site !== ADM_PROCESS && site !== PAYMENT_PROCESS) {
        handleRedirectHome()
      }
    }
  }, [
    recurrenceData,
    callMeData,
    bnplData,
    pagaTuPrestamoData,
    cbkUssdData,
    billingData,
    admData,
    sessionBiData,
    deleteAccountBiData,
    sendARechargeData,
    demoBiData,
    failureBiData,
  ])

  useEffect(() => {
    if (extractSiteFromPath() === ADM_PROCESS) {
      const fetchData = async () => {
        const uuidAdm = getLocalStorage(UUID_PROCESS_ADM)
        if (uuidAdm) {
          const response = await checkProcessAdm(uuidAdm)
          setResponseService(response)
          setProcessingPayment(true)
          clearLocalStorage(PROCESO_PAGO)
          clearLocalStorage(UUID_PROCESS_ADM)
        } else {
          handleRedirectHome()
        }
      }
      fetchData()
    }
  }, [])

  useEffect(() => {
    clearLocalStorage(MENU_REDIRECT)
    if (extractSiteFromPath() === PAYMENT_PROCESS) {
      const url = getLocalStorage(PAYMENT_PROCESS)
      if (!url) {
        handleRedirectHome()
      } else {
        window.location.href = window.location.origin + url
      }
    }
  }, [])

  const processMetrics = metricsData => {
    const { metricType, metricName } = metricsData

    let elementLocationMetrics = ''
    let elementLocationCodeMetrics = ''
    let elementNameMetrics = ''
    let msisdnMetrics = ''

    if (pagaTuPrestamoData) {
      elementLocationMetrics = 'Recuperación de Cartera'
      elementLocationCodeMetrics = 'DEU'
      msisdnMetrics = pagaTuPrestamoData.msisdn
    } else if (cbkUssdData) {
      elementLocationMetrics = 'USSD'
      elementLocationCodeMetrics = 'USD'
      msisdnMetrics = cbkUssdData.msisdnPayer
    } else if (callMeData) {
      elementLocationMetrics = 'Who Call Me'
      if (metricType !== 'Menu') {
        if (metricType !== 'home') {
          elementLocationCodeMetrics =
            msisdnRechargeMetrics === '_A' ? 'WCA' : 'WCB'
        } else {
          elementLocationCodeMetrics = 'WCM'
        }
      }
      msisdnMetrics = callMeData.msisdnPayer
    } else if (recurrenceData) {
      elementLocationMetrics = 'Recurrencia'
      elementLocationCodeMetrics = 'REC'
      msisdnMetrics = recurrenceData.msisdn
    } else if (billingData) {
      elementLocationMetrics = 'Factura Electrónica'
      elementLocationCodeMetrics = 'FEL'
      msisdnMetrics = '-'
    } else if (deleteAccountBiData) {
      elementLocationMetrics = 'PAC'
      elementLocationCodeMetrics = 'PAC'
      msisdnMetrics = '-'
    } else if (admData) {
      elementLocationMetrics = 'ADM'
      elementLocationCodeMetrics = 'ADM'
      msisdnMetrics = admData.responseContent?.msisdn || '-'
    } else if (sendARechargeData) {
      elementLocationMetrics = 'EnviaRecarga'
      elementLocationCodeMetrics = 'ENR'
      msisdnMetrics = sendARechargeData.msisdnPayer
    } else if (bnplData) {
      msisdnMetrics = bnplData.msisdn
    }

    const originalElementLocationMetrics = elementLocationMetrics
    const originalElementLocationCodeMetrics = elementLocationCodeMetrics

    if (metricType === 'Menu') {
      elementLocationMetrics = 'HOME'
      elementLocationCodeMetrics = 'HOM'
      elementNameMetrics = metricName
    } else if (metricType === 'CerrarSesiónADM') {
      elementLocationMetrics = 'ADM'
      elementLocationCodeMetrics = 'ADM'
      elementNameMetrics = metricName
    } else if (metricType === 'CheckOut_') {
      if (callMeData) {
        elementNameMetrics =
          metricType +
          currentCategoryMetrics +
          metricName +
          msisdnRechargeMetrics
      } else {
        elementNameMetrics = metricType + currentCategoryMetrics + metricName
      }
    } else if (
      metricType === 'category' ||
      metricType === 'BtnContinuar' ||
      metricType === 'Carrousel'
    ) {
      if (callMeData) {
        elementNameMetrics =
          metricName + (msisdnRechargeMetrics === '_B' ? '-B' : '-A')
      } else {
        elementNameMetrics = metricName
      }
    } else if (
      callMeData &&
      metricType !== 'home' &&
      metricType !== 'PagoExitoso' &&
      metricType !== 'ExitosoFel' &&
      metricType !== 'Exitoso_FEL'
    ) {
      elementNameMetrics = metricName + msisdnRechargeMetrics
    } else if (metricType === 'PagoExitoso') {
      let categoryPagoExitosoMetrics
      if (currentCategoryMetrics === 'Recarga-') {
        categoryPagoExitosoMetrics = 'OkRecarga'
      } else if (currentCategoryMetrics === 'Paquetes-') {
        categoryPagoExitosoMetrics = 'OkPaquete'
      } else if (currentCategoryMetrics === 'Combo-') {
        categoryPagoExitosoMetrics = 'OkCombo'
      }
      if (callMeData) {
        elementNameMetrics =
          metricName + categoryPagoExitosoMetrics + msisdnRechargeMetrics
      } else {
        elementNameMetrics = metricName + categoryPagoExitosoMetrics
      }
    } else if (metricType === 'ExitosoFel') {
      elementNameMetrics =
        (currentCategoryMetrics === 'Recarga-' ? 'ExitosoR_' : 'ExitosoP_') +
        metricName
    } else {
      elementNameMetrics = metricName
    }

    if (metricType !== 'Menu') {
      elementLocationMetrics = originalElementLocationMetrics
      elementLocationCodeMetrics = originalElementLocationCodeMetrics
    }

    return {
      elementLocationMetrics,
      elementLocationCodeMetrics,
      elementNameMetrics,
      msisdnMetrics,
    }
  }

  const sendMetrics = async metricsData => {
    const {
      elementLocationMetrics,
      elementLocationCodeMetrics,
      elementNameMetrics,
      msisdnMetrics,
    } = processMetrics(metricsData)

    const elementNameCodeMetrics =
      elementNameCodeMap[elementNameMetrics] || 'CódigoNoEncontrado'
    try {
      const data = {
        elementLocation: elementLocationMetrics,
        elementLocationCode: elementLocationCodeMetrics,
        elementName: elementNameMetrics,
        elementNameCode: elementNameCodeMetrics,
        msisdn: msisdnMetrics,
        code: code ?? '-',
      }
      const url = BACK_END_ROUTES.registerMetrics
      await postAxiosService(url, data)
      if (
        elementNameCodeMetrics === '137' ||
        elementNameCodeMetrics === '138'
      ) {
        setLocalStorage(METRICS_SESSION, JSON.stringify(data))
      }
    } catch (error) {}
  }

  const toggleMenu = () => {
    if (!menuVisible) {
      const metricsData = {
        metricType: 'Menu',
        metricName: 'Menu_DespliegueMenuPrincipal',
      }
      sendMetrics(metricsData)
    }
    setMenuVisible(!menuVisible)
  }

  const getMetricsData = (type, opcion) => ({
    metricType: 'Menu',
    metricName:
      type === MENU_TYPE_VERTICAL ? `Menu_${opcion}` : `CintaGris_${opcion}`,
  })

  const isAnyDataPresent = () => {
    return (
      pagaTuPrestamoData ||
      cbkUssdData ||
      callMeData ||
      recurrenceData ||
      sendARechargeData
    )
  }

  const redirectMenu = async menuType => {
    setLocalStorage(MENU_REDIRECT, menuType)
    window.location.href = window.location.origin
  }

  const handleOptionClick = async (opcion, type) => {
    if (opcionSeleccionada !== opcion) {
      if (opcion !== MENU_OPTION_CERRARSESION) {
        setLocalStorage(MENU_CATEGORY, opcion)
        setOpcionSeleccionada(opcion)
      }

      const metricsData = getMetricsData(type, opcion)
      await sendMetrics(metricsData)

      const actionMap = {
        [MENU_OPTION_SUPERPACKS]: async () => {
          clearLocalStorage(PRODUCT_SELECTED_HOME)
          clearLocalStorage(MENU_REDIRECT)
          handleRedirectHome()
        },
        [MENU_OPTION_RECARGAS]: async () => {
          clearLocalStorage(PRODUCT_SELECTED_HOME)
          clearLocalStorage(MENU_REDIRECT)
          handleRedirectHome()
        },
        [MENU_OPTION_ADM]: async () => {
          if (code) {
            const url = code ? `${claroAdm}?code=${code}` : ''
            window.location.href = window.location.origin + url
          } else {
            redirectMenu(opcion)
          }
        },
        [MENU_OPTION_PAGARDEUDA]: async () => {
          if (code) {
            const url = code ? `${claroPagaTuPrestamo}?code=${code}` : ''
            window.location.href = window.location.origin + url
          } else {
            redirectMenu(opcion)
          }
        },
        [MENU_OPTION_ENVIARECARGA]: async () => {
          if (code) {
            const url = code ? `${claroSendARecharge}?code=${code}` : ''
            window.location.href = window.location.origin + url
          } else {
            redirectMenu(opcion)
          }
        },
        [MENU_OPTION_CERRARSESION]: async () => {
          if (admData) {
            const metricsData = {
              metricType: 'CerrarSesiónADM',
              metricName: 'Cerrar Sesión',
            }
            await sendMetrics(metricsData)
            exitFunctionAdm()
          } else {
            if (isAnyDataPresent()) {
              const metricsData = {
                metricType: 'CheckOut_',
                metricName: 'CerrarSesión',
              }
              await sendMetrics(metricsData)
            }
            clearStorageLandings()
            window.location.href = window.location.origin + claroHomeAuth
          }
        },
      }

      const action = actionMap[opcion]
      if (typeof action === 'function') {
        await action()
      }
    }
  }

  const isAuthenticatedAdm = !!getLocalStorage(AUTH_TOKENS_ADM)

  const formatNumero = value => {
    if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return value.toString()
      } else {
        return value.toFixed(2)
      }
    }
    return ''
  }

  const msisdnHeader = [
    recurrenceData?.msisdn,
    pagaTuPrestamoData?.msisdn,
    cbkUssdData?.msisdnPayer,
    bnplData?.msisdn,
    callMeData?.msisdnPayer,
    sendARechargeData?.msisdnPayer,
    demoBiData?.msisdn,
    admData && tokenAdm.valid && getLocalStorage(MSISDN_ADM) && !loadingAdm
      ? getLocalStorage(MSISDN_ADM)
      : '',
  ]
    .filter(Boolean)
    .map(msisdn => msisdn.slice(3))[0]

  const dataList = [
    recurrenceData,
    callMeData,
    bnplData,
    pagaTuPrestamoData,
    cbkUssdData,
    billingData,
    admData,
    sessionBiData,
    deleteAccountBiData,
    failureBiData,
    sendARechargeData,
    demoBiData,
    pruebasData,
  ]

  const allDataNull = dataList.every(data => data === null)
  const allFlagsFalse = !notPagaTuPrestamo && !notAdm && !processingPayment

  const dependencies = {
    recurrenceData,
    isAcceptedTermsAndConditions,
    setIsAcceptedTermsAndConditions,
    payWithSavedCardRecurrence,
    payWithNewCardRecurrence,
    selectedSavedCard,
    setSelectedSavedCard,
    saveCard,
    setSaveCard,
    year,
    setYear,
    month,
    setMonth,
    saveNewCard,
    setSaveNewCard,
    yearNewCard,
    setYearNewCard,
    monthNewCard,
    setMonthNewCard,
    cvv,
    setCvv,
    numberCard,
    setNumberCard,
    processingPayment,
    setProcessingPayment,
    responseService,
    setResponseService,
    isVisibleSectionAddNewCard,
    setIsVisibleSectionAddNewCard,
    isVisibleAddNewPaymentMethod,
    setIsVisibleAddNewPaymentMethod,
    maxLengthCvv,
    setMaxLengthCvv,
    maxLengthNumberCard,
    setMaxLengthNumberCard,
    isValidatedFieldsToPayWithSavedCard,
    isValidatedFieldsToPayWithNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    setIsEnabledPayWithSavedCard,
    getCalledMeData,
    callMeData,
    payWithSavedCardCallMe,
    payWithNewCardCallMe,
    callMeProduct,
    getBnplData,
    bnplData,
    payWithSavedCardBnpl,
    payWithNewCardBnpl,
    pagaTuPrestamoData,
    payWithSavedCardPagaTuPrestamo,
    payWithNewCardPagaTuPrestamo,
    getCbkUssdData,
    cbkUssdData,
    payWithSavedCardCallBackUssd,
    payWithNewCardCallBackUssd,
    cbkUssdProduct,
    billingData,
    processingBilling,
    setProcessingBilling,
    getAdmData,
    admData,
    setAdmData,
    tokenAdm,
    setTokenAdm,
    productsAdm,
    setProductsAdm,
    activeAdm,
    selectedPackAdm,
    setSelectedPackAdm,
    loadingAdm,
    setLoadingAdm,
    checkProcessAdm,
    payWithAccountBiRecurrence,
    payWithAccountBiWhoCalledMe,
    payWithAccountBiPagaTuPrestamo,
    payWithAccountBiCallBackUssd,
    payWithAccountBiBnpl,
    payWithAccountBiSendARecharge,
    payWithAccountBiDemoBi,
    payWithNewAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewAccountBi,
    selectAccountBi,
    setSelectAccountBi,
    requestId,
    setRequestId,
    urlAccountBi,
    setUrlAccountBi,
    urltiendaApps,
    setUrltiendaApps,
    sessionBiData,
    deleteAccountBiData,
    deleteAccountBi,
    clearData,
    currentCategoryMetrics,
    setCurrentCategoryMetrics,
    setMsisdnRechargeMetrics,
    sendMetrics,
    code,
    canClickButton,
    setCanClickButton,
    setPagaTuPrestamoData,
    setOpcionSeleccionada,
    exitFunctionAdm,
    setExitFunctionAdm,
    notAdm,
    setNotAdm,
    getImage,
    formatNumero,
    sendARechargeData,
    sendARechargeProduct,
    payWithSavedCardSendARecharge,
    payWithNewCardSendARecharge,
    isAuthenticatedAdm,
    pagaTuPrestamoProduct,
    demoBiData,
    setDemoBiData,
    responseServiceBilling,
    setResponseServiceBilling,
    codeBilling,
    setCodeBilling,
    pruebasData,
  }

  const contextValue = useMemo(
    () => ({
      ...dependencies,
    }),
    Object.values(dependencies),
  )

  return (
    <ClaroPayContext.Provider value={contextValue}>
      <div className="claro">
        <Header mssidn={msisdnHeader} toggleMenu={toggleMenu} />

        <Menu
          opcionSeleccionada={opcionSeleccionada}
          toggleMenu={toggleMenu}
          handleOptionClick={handleOptionClick}
          isAuthenticated={admData ? isAuthenticatedAdm : true}
          menuVisible={menuVisible}
        />

        {dataList.some(data => data !== null) && (
          <div className="claro__container">{children}</div>
        )}

        {notPagaTuPrestamo && (
          <div className="claro__container">
            <div className="claro__container__process">
              <div className="claro__container__process__waiting">
                <img
                  className="claro__container__process__image"
                  src={notPagaTuPrestamoImage}
                  alt="notPagaTuPrestamoImage"
                />
                <p className="claro__container__process__generalMessage">
                  No tienes Préstamo Pendiente.
                </p>
                <p className="claro__container__process__generalMessage">
                  Puedes hacer uso de las otras opciones del Menú Principal.
                </p>
              </div>

              <NextButton
                textBtn="Menú Principal"
                onClick={handleRedirectHome}
                enabled
              />
            </div>
          </div>
        )}
        {notAdm && (
          <div className="claro__container">
            <div className="claro__container__process">
              <div className="claro__container__process__waiting">
                <img
                  className="claro__container__process__image"
                  src={notAdmImage}
                  alt="notAdmImage"
                />
                <p className="claro__container__process__generalMessage">
                  Por ahora no tenemos un paquete disponible para ti…{' '}
                  <img
                    src={sad}
                    alt="Sad"
                    className="claro__container__process__error__imageEmoji"
                  />
                </p>
              </div>

              <NextButton
                textBtn="Menú Principal"
                onClick={handleRedirectHome}
                enabled
              />
            </div>
          </div>
        )}

        {((allDataNull && allFlagsFalse) || loadingAdm) && (
          <div className="claro__container">
            <LoadingScreen />
          </div>
        )}

        <Footer />
      </div>
    </ClaroPayContext.Provider>
  )
}

export default ClaroPayLayout
