import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import ClaroPayPaymentOptions from '../ClaroPayPaymentOptions'
import ClaroPayPaymentOptionsWithBi from '../ClaroPayPaymentOptionsWithBi'

import PaymentButton from '../../components/PaymentButton'
import { generarUuid } from '../../components/GenerarUuid'
import SelectedOffer from '../../components/SelectedOffer'
import MoreOptions from '../../components/MoreOptions'
import Categories from '../../components/Categories'
import CarouselSwiper from '../../components/CarouselSwiper'
import NextButton from '../../components/NextButton'

import localRoutes from '../../constants/routes'
import Country from '../../constants/Country'

import acceptedTerms from '../../assets/img/acceptedTerms.svg'

const ClaroWhoCalledMe = () => {
  const history = useHistory()

  const claroPayContext = useContext(ClaroPayContext)

  const { countryCode } = Country()

  const {
    isVisibleSectionAddNewCard,
    isEnabledPayWithSavedCard,
    isEnabledPayWithNewCard,
    callMeData,
    setProcessingPayment,
    setIsVisibleSectionAddNewCard,
    payWithSavedCardCallMe,
    payWithNewCardCallMe,
    setResponseService,
    isValidatedFieldsToPayWithNewCard,
    isValidatedFieldsToPayWithSavedCard,
    selectAccountBi,
    iIsVisibleAddNewAccountBi,
    setIsVisibleAddNewPaymentMethod,
    payWithNewAccountBi,
    payWithAccountBiWhoCalledMe,
    setIsVisibleAddNewAccountBi,
    setCurrentCategoryMetrics,
    setMsisdnRechargeMetrics,
    clearData,
    sendMetrics,
    code,
    canClickButton,
    setCanClickButton,
  } = claroPayContext

  const {
    routesClaroPay: { claroPayPaymentProcess },
  } = localRoutes

  const [state, setState] = useState({
    rechargeOwn: false,
    rechargeWho: true,
    packs: [],
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: { icons: [] },
      description: [],
      mainDescription: '',
    },
    topup: [],
    bundle: [],
    currentCategory: 'bundle',
    msisdnBeneficiary: '',
    msisdnPayer: '',
    statusPayer: '',
    selectedProduct: false,
    alias: [],
    indicator: false,
  })

  const [currentRealIndex, setCurrentRealIndex] = useState(0)
  const [activeSwiper, setActiveSwiper] = useState(false)

  useEffect(() => {
    setMsisdnRechargeMetrics(state.rechargeOwn ? '_A' : '_B')
  }, [setMsisdnRechargeMetrics, state.rechargeOwn])

  useEffect(() => {
    const bundle = callMeData.recommendedProducts.bundle.toSorted(
      (a, b) => b.amount - a.amount,
    )

    let topup
    if (countryCode !== '504') {
      topup = callMeData.recommendedProducts.topup
        .filter(product => product.productId !== 'TOPUP200')
        .toSorted((a, b) => b.amount - a.amount)
    } else {
      topup = callMeData.recommendedProducts.topup.toSorted(
        (a, b) => b.amount - a.amount,
      )
    }

    const msisdnPayer = callMeData.msisdnPayer
    const msisdnBeneficiary = callMeData.msisdnBeneficiary
    const statusPayer = callMeData.statusPayer
    const alias = callMeData.alias
    const indicator = callMeData.indicator
    const pack = bundle[0]
    const packs = bundle
    const currentCategory = 'bundle'

    setCurrentCategoryMetrics('Paquetes-')

    setState(prevState => ({
      ...prevState,
      bundle,
      topup,
      pack,
      packs,
      msisdnPayer,
      msisdnBeneficiary,
      statusPayer,
      currentCategory,
      alias,
      indicator,
    }))
    setActiveSwiper(true)
  }, [callMeData])

  useEffect(() => {
    const metricsDataHome = {
      metricType: 'home',
      metricName: 'Home',
    }
    const metricsDataCategory = {
      metricType: 'category',
      metricName:
        state.currentCategory === 'bundle' ? 'Superpacks' : 'Recargas',
    }

    if (code) {
      sendMetrics(metricsDataHome)
      sendMetrics(metricsDataCategory)
    }
  }, [code])

  const handleRechargeNumber = () => {
    setState(prevState => ({
      ...prevState,
      rechargeOwn: !prevState.rechargeOwn,
      rechargeWho: !prevState.rechargeWho,
    }))
  }

  const metricBotonPagar = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar',
    }
    sendMetrics(metricsData)
  }

  const handlePayment = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleSectionAddNewCard(false)
    metricBotonPagar()
    const msisdn = state.rechargeOwn
      ? state.msisdnPayer
      : state.msisdnBeneficiary
    history.push(claroPayPaymentProcess)
    let response
    const isPaymentValid = isVisibleSectionAddNewCard
      ? isValidatedFieldsToPayWithNewCard()
      : isValidatedFieldsToPayWithSavedCard()

    if (isPaymentValid) {
      response = isVisibleSectionAddNewCard
        ? await payWithNewCardCallMe(state.pack, msisdn, uuid)
        : await payWithSavedCardCallMe(state.pack, msisdn, uuid)
    }
    setResponseService(response)
  }

  const metricBotonPagarBi = () => {
    const metricsData = {
      metricType: 'CheckOut_',
      metricName: 'BotonPagar-Cuenta',
    }
    sendMetrics(metricsData)
  }

  const handlePaymentAccountBi = async () => {
    setCanClickButton(false)
    setResponseService(null)
    const uuid = generarUuid()
    setProcessingPayment(true)
    setIsVisibleAddNewPaymentMethod(false)
    metricBotonPagarBi()
    const msisdn = state.rechargeOwn
      ? state.msisdnPayer
      : state.msisdnBeneficiary
    history.push(claroPayPaymentProcess)
    const response = await payWithAccountBiWhoCalledMe(state.pack, msisdn, uuid)
    setResponseService(response)
  }

  const handlePaymentNewAccountBi = async () => {
    setIsVisibleAddNewPaymentMethod(false)
    setResponseService(null)
    setProcessingPayment(true)
    setIsVisibleAddNewAccountBi(true)
    metricBotonPagarBi()
    const msisdn = state.rechargeOwn
      ? state.msisdnPayer
      : state.msisdnBeneficiary
    history.push(claroPayPaymentProcess)
    payWithNewAccountBi(state.pack.productId, msisdn)
  }

  const handleSelectedProduct = () => {
    setState(prevState => ({
      ...prevState,
      selectedProduct: !prevState.selectedProduct,
    }))

    if (state.selectedProduct) {
      setState(prevState => ({
        ...prevState,
        pack: packs[0],
      }))
      const metricsData = {
        metricType: 'CheckOut_',
        metricName: 'MasOpciones',
      }
      sendMetrics(metricsData)
    } else {
      const metricsData = {
        metricType: 'BtnContinuar',
        metricName:
          'Botón_' +
          (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
          '_Continuar',
      }
      sendMetrics(metricsData)
    }

    clearData()
  }

  const handleCategory = category => {
    setCurrentRealIndex(0)
    setActiveSwiper(false)
    setTimeout(() => {
      setActiveSwiper(true)
    }, 1)
    setCurrentCategoryMetrics(category === 'bundle' ? 'Paquetes-' : 'Recarga-')
    const metricsData = {
      metricType: 'category',
      metricName: category === 'bundle' ? 'Superpacks' : 'Recargas',
    }
    sendMetrics(metricsData)

    const packs = category === 'bundle' ? state.bundle : state.topup
    setState(prevState => ({
      ...prevState,
      currentCategory: category,
      pack: packs[0],
      packs,
    }))
  }

  const handleSlideChange = swiper => {
    const newRealIndex = swiper.realIndex
    if (newRealIndex !== currentRealIndex) {
      setCurrentRealIndex(newRealIndex)
      const pack = state.packs[newRealIndex]
      setState(prevState => ({
        ...prevState,
        pack,
      }))
    }
  }

  const {
    currentCategory,
    pack,
    packs,
    bundle,
    msisdnBeneficiary,
    statusPayer,
    selectedProduct,
    indicator,
    rechargeOwn,
    rechargeWho,
  } = state

  const textBtn = `Pagar ${pack.currency}${pack.amount}`

  return (
    <div className="claro__container__wcm">
      <div className="claro__container__selectRechargeNumber">
        {statusPayer !== undefined && statusPayer ? (
          <>
            <div
              className="claro__container__selectRechargeNumber__option"
              onClick={handleRechargeNumber}
            >
              {rechargeWho ? (
                <div className="claro__container__selectRechargeNumber__option__image">
                  <img src={acceptedTerms} alt="acceptedTerms" />
                </div>
              ) : (
                <div className="claro__container__selectRechargeNumber__option__circleNotAccepted" />
              )}
              <div className="claro__container__selectRechargeNumber__option__number">
                Recargar el: {msisdnBeneficiary}
              </div>
            </div>

            <div
              className="claro__container__selectRechargeNumber__option"
              onClick={handleRechargeNumber}
            >
              {rechargeOwn ? (
                <div className="claro__container__selectRechargeNumber__option__image">
                  <img src={acceptedTerms} alt="acceptedTerms" />
                </div>
              ) : (
                <div className="claro__container__selectRechargeNumber__option__circleNotAccepted" />
              )}
              <div className="claro__container__selectRechargeNumber__option__number">
                Recargar mi número Claro
              </div>
            </div>
          </>
        ) : (
          <div className="claro__container__selectRechargeNumber__option">
            {rechargeWho && (
              <div className="claro__container__selectRechargeNumber__option__image">
                <img src={acceptedTerms} alt="acceptedTerms" />
              </div>
            )}
            <div className="claro__container__selectRechargeNumber__option__number">
              Recargar el: {msisdnBeneficiary}
            </div>
          </div>
        )}
      </div>

      {!selectedProduct ? (
        <>
          <Categories
            currentCategory={currentCategory}
            handleCategory={handleCategory}
            bundle={bundle}
          />

          {activeSwiper && (
            <CarouselSwiper
              selectedProduct
              handleSlideChange={handleSlideChange}
              pack={pack}
              packs={packs}
              currentCategory={currentCategory}
              sendMetrics={sendMetrics}
            />
          )}

          <NextButton
            textBtn="Continuar"
            onClick={handleSelectedProduct}
            enabled
          />
        </>
      ) : (
        <>
          <SelectedOffer pack={pack} currentCategory={currentCategory} />

          <MoreOptions handleSelectedProduct={handleSelectedProduct} />

          {countryCode === '507' ? (
            <ClaroPayPaymentOptionsWithBi />
          ) : (
            <ClaroPayPaymentOptions />
          )}

          <PaymentButton
            selectAccountBi={selectAccountBi}
            iIsVisibleAddNewAccountBi={iIsVisibleAddNewAccountBi}
            indicator={indicator}
            countryCode={countryCode}
            textBtn={textBtn}
            handlePaymentNewAccountBi={handlePaymentNewAccountBi}
            handlePaymentAccountBi={handlePaymentAccountBi}
            canClickButton={canClickButton}
            handlePayment={handlePayment}
            isVisibleSectionAddNewCard={isVisibleSectionAddNewCard}
            isEnabledPayWithNewCard={isEnabledPayWithNewCard}
            isEnabledPayWithSavedCard={isEnabledPayWithSavedCard}
          />
        </>
      )}
    </div>
  )
}

export default ClaroWhoCalledMe
