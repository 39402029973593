import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import ClaroPayContext from '../../context/ClaroPayContext'

import {
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
} from '../../services/storage'

import {
  PRODUCT_SELECTED_HOME,
  MENU_REDIRECT,
  MENU_CATEGORY,
} from '../../constants/ClaroPayConstants'
import localRoutes from '../../constants/routes'

import Categories from '../../components/Categories'
import CarouselSwiper from '../../components/CarouselSwiper'
import NextButton from '../../components/NextButton'

import Country from '../../constants/Country'

const ClaroHome = () => {
  const history = useHistory()

  const { countryCode } = Country()

  const {
    routesClaroPay: { claroHomeAuth, claroHomePayment },
  } = localRoutes

  const {
    isLoading,
    setHomeProduct,
    setCurrentCategoryMetrics,
    clearData,
    sendMetrics,
    getProducts,
    products,
    isAuthenticated,
    homeProduct,
    setOpcionSeleccionada,
    redirectMenu,
  } = useContext(ClaroPayContext)

  const [state, setState] = useState({
    packs: [],
    pack: {
      amount: 0,
      currency: 0,
      subtitle: '',
      validity: '',
      socialMedia: {
        title: '',
        icons: [],
      },
      description: [],
      mainDescription: '',
    },
    topup: [],
    bundle: [],
    currentCategory: 'bundle',
    alias: [],
    indicator: false,
  })

  const [currentRealIndex, setCurrentRealIndex] = useState(0)
  const [activeSwiper, setActiveSwiper] = useState(false)

  const getPack = (menuCategory, bundle, topup) => {
    if (menuCategory) {
      return menuCategory === 'Superpacks' && bundle.length > 0
        ? bundle[0]
        : topup[0]
    } else if (Object.keys(homeProduct).length !== 0) {
      return homeProduct.productType === 'BUNDLE' ? bundle[0] : topup[0]
    } else {
      return bundle.length > 0 ? bundle[0] : topup[0]
    }
  }

  const getPackDetails = (pack, bundle, topup) => {
    const packs = pack.productType === 'BUNDLE' ? bundle : topup
    const currentCategory = pack.productType === 'BUNDLE' ? 'bundle' : 'topup'

    setOpcionSeleccionada(
      currentCategory === 'bundle' ? 'Superpacks' : 'Recargas',
    )

    setCurrentCategoryMetrics(
      currentCategory === 'bundle' ? 'Paquetes-' : 'Recarga-',
    )

    return {
      packs,
      currentCategory,
    }
  }

  const fetchProducts = async () => {
    try {
      const { productsData, calledService } = await getProducts()

      if (Object.keys(productsData).length > 0) {
        const arrProducts = productsData.products

        const bundle = arrProducts
          .filter(product => product.productType === 'BUNDLE')
          .toSorted((a, b) => b.amount - a.amount)

        let topup
        if (countryCode !== '504') {
          topup = arrProducts
            .filter(
              product =>
                product.productType === 'TOPUP' &&
                product.productId !== 'TOPUP200',
            )
            .toSorted((a, b) => b.amount - a.amount)
        } else {
          topup = arrProducts
            .filter(product => product.productType === 'TOPUP')
            .toSorted((a, b) => b.amount - a.amount)
        }

        const menuCategory = getLocalStorage(MENU_CATEGORY) || null
        const pack = getPack(menuCategory, bundle, topup)
        menuCategory && clearLocalStorage(MENU_CATEGORY)

        const { packs, currentCategory } = getPackDetails(pack, bundle, topup)

        const metricsDataHome = { metricType: 'home', metricName: 'Home' }
        const metricsDataCategory = {
          metricType: 'category',
          metricName: currentCategory === 'bundle' ? 'Superpacks' : 'Recargas',
        }

        if (Object.keys(homeProduct).length === 0 && calledService) {
          sendMetrics(metricsDataHome)
          sendMetrics(metricsDataCategory)
        }

        setState({
          bundle,
          topup,
          pack,
          packs,
          currentCategory,
        })
      }
      setActiveSwiper(true)
    } catch (error) {}
  }

  const menuRedirect = getLocalStorage(MENU_REDIRECT)
  const productSelected = JSON.parse(getLocalStorage(PRODUCT_SELECTED_HOME))

  useEffect(() => {
    if (menuRedirect) {
      if (isAuthenticated) {
        redirectMenu(menuRedirect)
      } else {
        history.push(claroHomeAuth)
      }
    } else if (productSelected) {
      if (isAuthenticated) {
        history.push(claroHomePayment)
      } else {
        history.push(claroHomeAuth)
      }
    } else {
      fetchProducts()
    }
  }, [])

  const handleSelectedProduct = async () => {
    setHomeProduct(pack)
    const product = pack
    clearData()
    const metricsData = {
      metricType: 'BtnContinuar',
      metricName:
        'Botón_' +
        (state.currentCategory === 'bundle' ? 'Paquetes' : 'Recargas') +
        '_Continuar',
    }
    if (isAuthenticated) {
      sendMetrics(metricsData)
      const ahora = new Date()
      const expiracion = ahora.getTime() + 30 * 60000
      const objetoConExpiracion = { product, expiracion: expiracion }
      setLocalStorage(
        PRODUCT_SELECTED_HOME,
        JSON.stringify(objetoConExpiracion),
      )
      history.push(claroHomePayment)
    } else {
      sendMetrics(metricsData)
      const ahora = new Date()
      const expiracion = ahora.getTime() + 30 * 60000
      const objetoConExpiracion = { product, expiracion: expiracion }
      setLocalStorage(
        PRODUCT_SELECTED_HOME,
        JSON.stringify(objetoConExpiracion),
      )
      window.location.href = window.location.origin
    }
  }

  const handleCategory = category => {
    setCurrentRealIndex(0)
    setActiveSwiper(false)
    setTimeout(() => {
      setActiveSwiper(true)
    }, 1)
    setOpcionSeleccionada(category === 'bundle' ? 'Superpacks' : 'Recargas')

    setCurrentCategoryMetrics(category === 'bundle' ? 'Paquetes-' : 'Recarga-')
    const metricsData = {
      metricType: 'category',
      metricName: category === 'bundle' ? 'Superpacks' : 'Recargas',
    }
    sendMetrics(metricsData)

    const packs = category === 'bundle' ? state.bundle : state.topup
    setState(prevState => ({
      ...prevState,
      currentCategory: category,
      pack: packs[0],
      packs,
    }))
  }

  const handleSlideChange = swiper => {
    const newRealIndex = swiper.realIndex
    if (newRealIndex !== currentRealIndex) {
      setCurrentRealIndex(newRealIndex)
      const pack = state.packs[newRealIndex]
      setState(prevState => ({
        ...prevState,
        pack,
      }))
    }
  }

  const { currentCategory, pack, packs, bundle } = state

  return (
    <>
      {!isLoading && products && !menuRedirect && !productSelected && (
        <div className="claro__container__home">
          <Categories
            currentCategory={currentCategory}
            handleCategory={handleCategory}
            bundle={bundle}
          />

          {activeSwiper && (
            <CarouselSwiper
              selectedProduct
              handleSlideChange={handleSlideChange}
              pack={pack}
              packs={packs}
              currentCategory={currentCategory}
              sendMetrics={sendMetrics}
            />
          )}

          <NextButton
            textBtn="Continuar"
            onClick={handleSelectedProduct}
            enabled
          />
        </div>
      )}
    </>
  )
}

export default ClaroHome
